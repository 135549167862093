export const AUTOMATION_IDS = {
  LANDING_PAGE: {
    LATEST_ENCOUNTERS: 'latest-encounters',
  },
  SECTION_PAGE: {
    ALL_ENCOUNTERS: 'all-encounters',
  },
  TERTIARY_PAGE: {
    PROVIDER_NAME: 'provider-name',
    LOCATION: 'location',
    START_TIME: 'start-time',
    END_TIME: 'end-time',
  },
  NO_PAST_EVENTS_MESSAGE: 'no-past-events-message',
};
