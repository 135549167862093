import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {PastEventsPrimaryCard} from './past-events/pages/PastEventsPrimaryPage/PastEventsPrimaryCard';
import {PastEventsSecondaryPage} from './past-events/pages/PastEventsSecondaryPage/PastEventsSecondaryPage';
import {PastEventsTertiaryPage} from './past-events/pages/PastEventsTertiaryPage/PastEventsTertiaryPage';
import {RawDataPrimaryPage} from './raw-data/pages/RawDataPrimaryPage/RawDataPrimaryPage';
import {RawDataSecondaryPage} from './raw-data/pages/RawDataSecondaryPage/RawDataSecondaryPage';

export const primaryPageRoute = '/me/mobile/health-dashboard';
export const secondaryPageRoute = '/me/mobile/health-dashboard/past-events';
export const tertiaryPagePrefix = '/me/mobile/health-dashboard/past-events';
export const tertiaryPageRoute = `${tertiaryPagePrefix}/:pastEventId`;
export const rawDataPrimaryRoute = '/me/mobile/health-dashboard/raw-data';
export const rawDataSecondaryRoute = '/me/mobile/health-dashboard/raw-data/*';

export default function Root() {
  const rawHealthRecordsEnabled = config.getBoolean(
    'FEATURE_RAW_HEALTH_RECORDS_ENABLED'
  );
  const pastEventsTertiaryPageEnabled = config.getBoolean(
    'FEATURE_PAST_VISITS_DETAILS_ENABLED'
  );
  const proceduresEnabled = config.getBoolean('FEATURE_PROCEDURES_ENABLED');

  return (
    <BrowserRouter>
      <Routes>
        <Route path={primaryPageRoute} element={<PastEventsPrimaryCard />} />
        <Route
          path={secondaryPageRoute}
          element={<PastEventsSecondaryPage />}
        />
        {rawHealthRecordsEnabled && (
          <Route path={rawDataPrimaryRoute} element={<RawDataPrimaryPage />} />
        )}
        {rawHealthRecordsEnabled && (
          <Route
            path={rawDataSecondaryRoute}
            element={<RawDataSecondaryPage />}
          ></Route>
        )}
        {pastEventsTertiaryPageEnabled && (
          <Route
            path={tertiaryPageRoute}
            element={<PastEventsTertiaryPage />}
          />
        )}
        {proceduresEnabled}
      </Routes>
    </BrowserRouter>
  );
}
