import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';

interface ObjectListViewerProps {
  data: Record<string, any>[];
  columnsPerPage?: number;
}

const flattenObject = (obj, prefix = '') => {
  let result = {};

  const processEntry = (key, value, parentKey) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        processEntry(`[${index}]`, item, newKey);
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([k, v]) => processEntry(k, v, newKey));
    } else {
      result[newKey] = value;
    }
  };

  Object.entries(obj).forEach(([key, value]) =>
    processEntry(key, value, prefix)
  );

  return result;
};

const ObjectListViewer: React.FC<ObjectListViewerProps> = ({
  data,
  columnsPerPage = 2,
}) => {
  const theme = useTheme();
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [allKeys, setAllKeys] = useState<string[]>([]);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    const flattenedData = data.map(data => flattenObject(data));
    setRows(flattenedData);
    setAllKeys(Array.from(new Set(flattenedData.flatMap(Object.keys))));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const displayedKeys = allKeys.slice(
    page * columnsPerPage,
    (page + 1) * columnsPerPage
  );

  if (rows.length === 0) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px"
        sx={{backgroundColor: theme.palette.background.canvas}}
      >
        <Typography variant="body2">
          {columnsPerPage} columns per page
        </Typography>
        <TablePagination
          component="div"
          count={allKeys.length}
          rowsPerPage={columnsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} of ${count}`
          }
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: theme.palette.background.secondaryCanvas,
          maxHeight: '80vh',
          width: '100%',
        }}
      >
        <Table stickyHeader style={{tableLayout: 'fixed', width: '100%'}}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: theme.palette.text.emphasized,
                  backgroundColor: theme.palette.background.default,
                  width: '50px',
                }}
                scope="col"
              >
                #
              </TableCell>
              {displayedKeys.map(key => (
                <TableCell
                  key={key}
                  style={{
                    color: theme.palette.text.emphasized,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                  scope="col"
                >
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    color: theme.palette.text.emphasized,
                    backgroundColor: theme.palette.background.default,
                    width: '50px',
                  }}
                >
                  {index + 1}
                </TableCell>
                {displayedKeys.map(key => (
                  <TableCell
                    key={key}
                    style={{
                      color: theme.palette.text.emphasized,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                  >
                    {row[key] ?? ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ObjectListViewer;
