import {Stack, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  DateRangeIcon,
  LocationOnIcon,
  PersonIcon,
  VerilyMeThemeProvider,
} from '@verily-src/react-design-system';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {secondaryPageRoute} from '../../../Root';
import PastEventsService from '../../../services/PastEventsService';
import {AUTOMATION_IDS} from '../../automationIDs';

const RELOAD_INTERVAL_MS = 5000;

const iso8601ToLocaleDateString = (iso8601: string) => {
  const date = new Date(iso8601);
  return date.toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export function PastEventsTertiaryPage() {
  const theme = useTheme();
  const {t} = useTranslation();
  const [isLoading, setIsLoadingData] = useState(true);
  const darkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);

  const {pastEventId} = useParams<{pastEventId: string}>();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setIsLoadingData(true);
    const {cached, fresh} = PastEventsService.getAllEncountersDetails();
    // Use the cached Profile on the initial load, if available
    if (cached) updatePastEvents(cached.pastEvents);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updatePastEvents(resp.pastEvents))
      .catch(() => {
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoadingData(false);
      });
  }

  const updatePastEvents = (pastEvents: PastEvent[]) => {
    setPastEvents(pastEvents);
    setIsLoadingData(false);
  };

  useEffect(() => {
    document.title = `Verily Me | ${t('past-events-header')}`;
  }, []);

  useEffect(() => {
    if (pastEventId == undefined) {
      window.location.href = secondaryPageRoute;
    }
  }, [pastEventId]);

  const pastEvent = pastEvents.find(
    pastEvent => pastEvent.name === pastEventId
  );

  let headerText: string = ''; // Default, loading value
  if (pastEvent?.title) {
    headerText = pastEvent.title;
  } else if (pastEvent?.practitionerName) {
    headerText = t('past-event-provider-title', {
      providerName: pastEvent.practitionerName,
    });
  } else if (pastEvent?.location) {
    headerText = t('past-event-location-title', {
      location: pastEvent.location,
    });
  } else if (!isLoading) {
    headerText = t('past-events-header');
  }

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={darkModeEnabled}>
      <main>
        <Layout
          header={
            <Header
              headerText={headerText}
              hasBackButton={true}
              backButtonDescription={t('back-button-description')}
              onBackButtonClick={() => {
                window.location.href = secondaryPageRoute;
              }}
            />
          }
        >
          {isLoading ? (
            <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
              <PillSkeleton
                width={theme.spacing(17.5)}
                height={theme.spacing(3.5)}
              />
            </div>
          ) : pastEvent ? (
            <Stack gap={2}>
              {(pastEvent.startTime || pastEvent.endTime) && (
                <Stack direction="row" gap={1}>
                  <DateRangeIcon />
                  <Stack gap={1}>
                    {pastEvent.startTime && (
                      <Typography
                        data-testid={AUTOMATION_IDS.TERTIARY_PAGE.START_TIME}
                      >
                        {`${iso8601ToLocaleDateString(pastEvent.startTime)}${
                          pastEvent.endTime ? ' -' : ''
                        }`}
                      </Typography>
                    )}
                    {pastEvent.endTime && (
                      <Typography
                        data-testid={AUTOMATION_IDS.TERTIARY_PAGE.END_TIME}
                      >
                        {iso8601ToLocaleDateString(pastEvent.endTime)}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )}
              {pastEvent.practitionerName && (
                <Stack direction="row" gap={1}>
                  <PersonIcon />
                  <Typography
                    data-testid={AUTOMATION_IDS.TERTIARY_PAGE.PROVIDER_NAME}
                  >
                    {pastEvent.practitionerName}
                  </Typography>
                </Stack>
              )}
              {pastEvent.location && (
                <Stack direction="row" gap={1}>
                  <LocationOnIcon />
                  <Typography
                    data-testid={AUTOMATION_IDS.TERTIARY_PAGE.LOCATION}
                  >
                    {pastEvent.location}
                  </Typography>
                </Stack>
              )}
            </Stack>
          ) : (
            <div>
              <Typography>{t('past-event-not-found')}</Typography>
            </div>
          )}
        </Layout>
      </main>
    </VerilyMeThemeProvider>
  );
}
