/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "phr/backend/api/v1/phr_service.proto" (package "phr.backend.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Immunization as Immunization$ } from "../../../../proto/google/fhir/proto/r4/core/resources/immunization";
import { Encounter as Encounter$ } from "../../../../proto/google/fhir/proto/r4/core/resources/encounter";
/**
 * Represents a wrapper for an Encounter FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Encounter
 */
export interface Encounter {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The Encounter from FHIR.
     *
     * @generated from protobuf field: google.fhir.r4.core.Encounter fhir_encounter = 2;
     */
    fhirEncounter?: Encounter$;
}
/**
 * Represents a wrapper for a Immunization FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Immunization
 */
export interface Immunization {
    /**
     * AIP resource name for the Immunization
     *
     * Format: “immunization/{immunization_id}” is equivalent to the FHIR
     * Immunization path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The Immunization from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Immunization fhir_immunization = 2;
     */
    fhirImmunization?: Immunization$;
}
/**
 * The specific type of FHIR resource to query for
 *
 * @generated from protobuf message phr.backend.api.v1.FhirResource
 */
export interface FhirResource {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "immunization";
        /**
         * The FHIR Immunization resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Immunization immunization = 1;
         */
        immunization: Immunization;
    } | {
        oneofKind: "encounter";
        /**
         * The FHIR Encounter resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Encounter encounter = 2;
         */
        encounter: Encounter;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents all of the relevant metadata of a Immunization FHIR resource
 * to be displayed in the Vaccinations MFE
 *
 * @generated from protobuf message phr.backend.api.v1.Vaccination
 */
export interface Vaccination {
    /**
     * AIP resource name for the Immunization
     *
     * Format: "immunization/{immunization_id}" is equivalent to the FHIR
     * Immunization path with resource id.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the vaccine (CDC group name provided by enrichments)
     * CDC name map to VerilyMe name
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The date time in ISO 8601 based on administration
     * date (if provided by Zus)
     *
     * @generated from protobuf field: string date = 3;
     */
    date: string;
    /**
     * The location the vaccine administration (e.g. USSF Marina)
     *
     * @generated from protobuf field: string location = 4;
     */
    location: string;
}
/**
 * The specific type of PHR resource that maps from their
 * respective FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.HealthRecordResource
 */
export interface HealthRecordResource {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "vaccination";
        /**
         * Equivalent to the FHIR Immunization resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Vaccination vaccination = 1;
         */
        vaccination: Vaccination;
    } | {
        oneofKind: "pastEvent";
        /**
         * Equivalent to the FHIR Encounter resource
         *
         * @generated from protobuf field: phr.backend.api.v1.PastEvent past_event = 2;
         */
        pastEvent: PastEvent;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents all the relevant metadata of an Encounter FHIR Resource to be
 * displayed in both the Past Events Landing page and the Past Events Section
 *
 * @generated from protobuf message phr.backend.api.v1.PastEvent
 */
export interface PastEvent {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The start time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string start_time = 2;
     */
    startTime: string;
    /**
     * The end time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string end_time = 11;
     */
    endTime: string;
    /**
     * The name of the Practitioner from Zus FHIR
     * (-- api-linter: core::0122::name-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string practitioner_name = 3;
     */
    practitionerName: string;
    /**
     * The specialty of the Practitioner from Zus FHIR
     *
     * @generated from protobuf field: string practitioner_specialty = 4;
     */
    practitionerSpecialty: string;
    /**
     * The encounter type: (TBD - https://www.hl7.org/fhir/valueset-encounter-type.html)
     *
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * The encounter classification: (TBD - https://terminology.hl7.org/5.1.0/ValueSet-encounter-class.html)
     *
     * @generated from protobuf field: string classification = 6;
     */
    classification: string;
    /**
     * The organization where encounter took place (TBD - Encounter.location.managingOrganization.name)
     *
     * @generated from protobuf field: string organization = 7;
     */
    organization: string;
    /**
     * The location of the organization where encounter took place (TBD - Encounter.location.display)
     *
     * @generated from protobuf field: string location = 8;
     */
    location: string;
    /**
     * The reason for the encounter
     *
     * @generated from protobuf field: string reason = 9;
     */
    reason: string;
    /**
     * Provider notes from the visit (TBD on if this is needed)
     *
     * @generated from protobuf field: string visit_notes = 10;
     */
    visitNotes: string;
}
/**
 * Get all of a specific resource by type for a participant/patient
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest
 */
export interface GetHealthRecordResourcesByTypeRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * The maximum number of resources to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 resources will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous
     * `GetHealthRecordResourcesByTypeRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided
     * to `GetHealthRecordResourcesByTypeRequest`
     * must match the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
    /**
     * The type of FHIR Resource to query for
     *
     * @generated from protobuf field: phr.backend.api.v1.FhirResource resource = 4;
     */
    resource?: FhirResource;
}
/**
 * Get response message for return PHR resources
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse
 */
export interface GetHealthRecordResourcesByTypeResponse {
    /**
     * List of PHR resources to return
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.HealthRecordResource resources = 1;
     */
    resources: HealthRecordResource[];
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Request to get the N most recent encounters for a list of patients.
 *
 * @generated from protobuf message phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest
 */
export interface GetRecentEncountersForListOfPatientsRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * Number of most recent encounters to get for patient
     * (-- api-linter: core::0141::count-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: int32 num_encounters = 2;
     */
    numEncounters: number;
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Encounters type.
 *
 * @generated from protobuf message phr.backend.api.v1.Encounters
 */
export interface Encounters {
    /**
     * The list of encounters.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Encounter encounters = 1;
     */
    encounters: Encounter[];
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Request to get all encounters for a list of patients.
 *
 * @generated from protobuf message phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest
 */
export interface GetAllEncountersForListOfPatientsRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 encounters will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllEncountersForPatientRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllEncountersForPatientRequest`
     * must match the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Encounter$Type extends MessageType<Encounter> {
    constructor() {
        super("phr.backend.api.v1.Encounter", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "fhir_encounter", kind: "message", T: () => Encounter$ }
        ], { "google.api.resource": { type: "verily.health/Encounter", pattern: ["encounter/{encounter_id}"], plural: "encounters", singular: "encounter" } });
    }
    create(value?: PartialMessage<Encounter>): Encounter {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Encounter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Encounter): Encounter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.fhir.r4.core.Encounter fhir_encounter */ 2:
                    message.fhirEncounter = Encounter$.internalBinaryRead(reader, reader.uint32(), options, message.fhirEncounter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Encounter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.fhir.r4.core.Encounter fhir_encounter = 2; */
        if (message.fhirEncounter)
            Encounter$.internalBinaryWrite(message.fhirEncounter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Encounter
 */
export const Encounter = new Encounter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Immunization$Type extends MessageType<Immunization> {
    constructor() {
        super("phr.backend.api.v1.Immunization", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "fhir_immunization", kind: "message", T: () => Immunization$ }
        ], { "google.api.resource": { type: "verily.health/Immunization", pattern: ["immunization/{immunization_id}"], plural: "immunizations", singular: "immunization" } });
    }
    create(value?: PartialMessage<Immunization>): Immunization {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Immunization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Immunization): Immunization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.fhir.r4.core.Immunization fhir_immunization */ 2:
                    message.fhirImmunization = Immunization$.internalBinaryRead(reader, reader.uint32(), options, message.fhirImmunization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Immunization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.fhir.r4.core.Immunization fhir_immunization = 2; */
        if (message.fhirImmunization)
            Immunization$.internalBinaryWrite(message.fhirImmunization, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Immunization
 */
export const Immunization = new Immunization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FhirResource$Type extends MessageType<FhirResource> {
    constructor() {
        super("phr.backend.api.v1.FhirResource", [
            { no: 1, name: "immunization", kind: "message", oneof: "type", T: () => Immunization },
            { no: 2, name: "encounter", kind: "message", oneof: "type", T: () => Encounter }
        ]);
    }
    create(value?: PartialMessage<FhirResource>): FhirResource {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FhirResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FhirResource): FhirResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.Immunization immunization */ 1:
                    message.type = {
                        oneofKind: "immunization",
                        immunization: Immunization.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).immunization)
                    };
                    break;
                case /* phr.backend.api.v1.Encounter encounter */ 2:
                    message.type = {
                        oneofKind: "encounter",
                        encounter: Encounter.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).encounter)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FhirResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.Immunization immunization = 1; */
        if (message.type.oneofKind === "immunization")
            Immunization.internalBinaryWrite(message.type.immunization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Encounter encounter = 2; */
        if (message.type.oneofKind === "encounter")
            Encounter.internalBinaryWrite(message.type.encounter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.FhirResource
 */
export const FhirResource = new FhirResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Vaccination$Type extends MessageType<Vaccination> {
    constructor() {
        super("phr.backend.api.v1.Vaccination", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Vaccination>): Vaccination {
        const message = { id: "", name: "", date: "", location: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Vaccination>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Vaccination): Vaccination {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* string location */ 4:
                    message.location = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Vaccination, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* string location = 4; */
        if (message.location !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.location);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Vaccination
 */
export const Vaccination = new Vaccination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthRecordResource$Type extends MessageType<HealthRecordResource> {
    constructor() {
        super("phr.backend.api.v1.HealthRecordResource", [
            { no: 1, name: "vaccination", kind: "message", oneof: "type", T: () => Vaccination },
            { no: 2, name: "past_event", kind: "message", oneof: "type", T: () => PastEvent }
        ]);
    }
    create(value?: PartialMessage<HealthRecordResource>): HealthRecordResource {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HealthRecordResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HealthRecordResource): HealthRecordResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.Vaccination vaccination */ 1:
                    message.type = {
                        oneofKind: "vaccination",
                        vaccination: Vaccination.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).vaccination)
                    };
                    break;
                case /* phr.backend.api.v1.PastEvent past_event */ 2:
                    message.type = {
                        oneofKind: "pastEvent",
                        pastEvent: PastEvent.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).pastEvent)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HealthRecordResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.Vaccination vaccination = 1; */
        if (message.type.oneofKind === "vaccination")
            Vaccination.internalBinaryWrite(message.type.vaccination, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.PastEvent past_event = 2; */
        if (message.type.oneofKind === "pastEvent")
            PastEvent.internalBinaryWrite(message.type.pastEvent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.HealthRecordResource
 */
export const HealthRecordResource = new HealthRecordResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PastEvent$Type extends MessageType<PastEvent> {
    constructor() {
        super("phr.backend.api.v1.PastEvent", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "practitioner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "practitioner_specialty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "classification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "visit_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PastEvent>): PastEvent {
        const message = { name: "", startTime: "", endTime: "", practitionerName: "", practitionerSpecialty: "", type: "", classification: "", organization: "", location: "", reason: "", visitNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PastEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PastEvent): PastEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string start_time */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 11:
                    message.endTime = reader.string();
                    break;
                case /* string practitioner_name */ 3:
                    message.practitionerName = reader.string();
                    break;
                case /* string practitioner_specialty */ 4:
                    message.practitionerSpecialty = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                case /* string classification */ 6:
                    message.classification = reader.string();
                    break;
                case /* string organization */ 7:
                    message.organization = reader.string();
                    break;
                case /* string location */ 8:
                    message.location = reader.string();
                    break;
                case /* string reason */ 9:
                    message.reason = reader.string();
                    break;
                case /* string visit_notes */ 10:
                    message.visitNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PastEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string start_time = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 11; */
        if (message.endTime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.endTime);
        /* string practitioner_name = 3; */
        if (message.practitionerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.practitionerName);
        /* string practitioner_specialty = 4; */
        if (message.practitionerSpecialty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.practitionerSpecialty);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string classification = 6; */
        if (message.classification !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classification);
        /* string organization = 7; */
        if (message.organization !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.organization);
        /* string location = 8; */
        if (message.location !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.location);
        /* string reason = 9; */
        if (message.reason !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.reason);
        /* string visit_notes = 10; */
        if (message.visitNotes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.visitNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.PastEvent
 */
export const PastEvent = new PastEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourcesByTypeRequest$Type extends MessageType<GetHealthRecordResourcesByTypeRequest> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "resource", kind: "message", T: () => FhirResource, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourcesByTypeRequest>): GetHealthRecordResourcesByTypeRequest {
        const message = { patientIds: [], pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourcesByTypeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourcesByTypeRequest): GetHealthRecordResourcesByTypeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                case /* phr.backend.api.v1.FhirResource resource */ 4:
                    message.resource = FhirResource.internalBinaryRead(reader, reader.uint32(), options, message.resource);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourcesByTypeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        /* phr.backend.api.v1.FhirResource resource = 4; */
        if (message.resource)
            FhirResource.internalBinaryWrite(message.resource, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest
 */
export const GetHealthRecordResourcesByTypeRequest = new GetHealthRecordResourcesByTypeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourcesByTypeResponse$Type extends MessageType<GetHealthRecordResourcesByTypeResponse> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse", [
            { no: 1, name: "resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HealthRecordResource }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourcesByTypeResponse>): GetHealthRecordResourcesByTypeResponse {
        const message = { resources: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourcesByTypeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourcesByTypeResponse): GetHealthRecordResourcesByTypeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.HealthRecordResource resources */ 1:
                    message.resources.push(HealthRecordResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourcesByTypeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.HealthRecordResource resources = 1; */
        for (let i = 0; i < message.resources.length; i++)
            HealthRecordResource.internalBinaryWrite(message.resources[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse
 */
export const GetHealthRecordResourcesByTypeResponse = new GetHealthRecordResourcesByTypeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentEncountersForListOfPatientsRequest$Type extends MessageType<GetRecentEncountersForListOfPatientsRequest> {
    constructor() {
        super("phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "num_encounters", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetRecentEncountersForListOfPatientsRequest>): GetRecentEncountersForListOfPatientsRequest {
        const message = { patientIds: [], numEncounters: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecentEncountersForListOfPatientsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecentEncountersForListOfPatientsRequest): GetRecentEncountersForListOfPatientsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 num_encounters */ 2:
                    message.numEncounters = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecentEncountersForListOfPatientsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 num_encounters = 2; */
        if (message.numEncounters !== 0)
            writer.tag(2, WireType.Varint).int32(message.numEncounters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest
 */
export const GetRecentEncountersForListOfPatientsRequest = new GetRecentEncountersForListOfPatientsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Encounters$Type extends MessageType<Encounters> {
    constructor() {
        super("phr.backend.api.v1.Encounters", [
            { no: 1, name: "encounters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Encounter }
        ]);
    }
    create(value?: PartialMessage<Encounters>): Encounters {
        const message = { encounters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Encounters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Encounters): Encounters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Encounter encounters */ 1:
                    message.encounters.push(Encounter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Encounters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Encounter encounters = 1; */
        for (let i = 0; i < message.encounters.length; i++)
            Encounter.internalBinaryWrite(message.encounters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Encounters
 */
export const Encounters = new Encounters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersForListOfPatientsRequest$Type extends MessageType<GetAllEncountersForListOfPatientsRequest> {
    constructor() {
        super("phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersForListOfPatientsRequest>): GetAllEncountersForListOfPatientsRequest {
        const message = { patientIds: [], pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersForListOfPatientsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersForListOfPatientsRequest): GetAllEncountersForListOfPatientsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersForListOfPatientsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest
 */
export const GetAllEncountersForListOfPatientsRequest = new GetAllEncountersForListOfPatientsRequest$Type();
/**
 * @generated ServiceType for protobuf service phr.backend.api.v1.PersonalHealthRecordService
 */
export const PersonalHealthRecordService = new ServiceType("phr.backend.api.v1.PersonalHealthRecordService", [
    { name: "GetRecentEncountersForListOfPatients", options: {}, I: GetRecentEncountersForListOfPatientsRequest, O: Encounters },
    { name: "GetAllEncountersForListOfPatients", options: {}, I: GetAllEncountersForListOfPatientsRequest, O: Encounters },
    { name: "GetHealthRecordResourcesByType", options: {}, I: GetHealthRecordResourcesByTypeRequest, O: GetHealthRecordResourcesByTypeResponse }
]);
