import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import PastEventsSectionCard from 'components/PastEventsSection/PastEventsSection';
import {secondaryPageRoute} from 'components/Root';
import {navigateToUrl} from 'single-spa';
export function PastEvents() {
  const pastEventsDarkModeEnabled = config.getBoolean(
    'FEATURE_DARK_MODE_ENABLED'
  );

  const onCardClick = async () => {
    const newUrl = new URL(window.location.href);
    newUrl.pathname = secondaryPageRoute;

    navigateToUrl(newUrl.toString());
  };

  return (
    <VerilyMeThemeProvider isDarkModeEnabled={pastEventsDarkModeEnabled}>
      <PastEventsSectionCard onClick={onCardClick} />
    </VerilyMeThemeProvider>
  );
}
