import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {ListItem} from '@verily-src/react-design-system';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import {tertiaryPagePrefix} from '../../../Root';

export interface PastVisitCardProps {
  pastEvent: PastEvent;
  linkToTertiaryPage: boolean;
}

function extractFormattedDateFromISO8601String(date: string) {
  // ISO 8601 date format:
  // YYYY-MM-DDThh:mm:ss+zz:zz
  const datePortion = date.split('T')[0];
  const [year, month, day] = datePortion.split('-');
  return `${new Date(
    Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
  ).toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  })}`;
}

export default function PastVisitCard({
  pastEvent,
  linkToTertiaryPage: linkToTertiaryPage,
}: PastVisitCardProps) {
  const featurePastVisitsDetailsEnabled = config.getBoolean(
    'FEATURE_PAST_VISITS_DETAILS_ENABLED'
  );

  const primaryTextParts: Array<string | React.ReactNode> = [];
  const secondaryTextParts: string[] = [];

  const title = (featurePastVisitsDetailsEnabled && pastEvent.title) ?? '';
  const formattedStartDate = pastEvent.startTime
    ? extractFormattedDateFromISO8601String(pastEvent.startTime)
    : 'No start date';

  const formattedDate = pastEvent.endTime
    ? `${formattedStartDate} - ${extractFormattedDateFromISO8601String(
        pastEvent.endTime
      )}`
    : formattedStartDate;

  primaryTextParts.push(formattedDate);

  if (title) {
    const titleUppercase = `${title[0].toUpperCase()}${title.slice(1)}`;
    primaryTextParts.push(titleUppercase);
  }

  if (pastEvent.location) {
    secondaryTextParts.push(pastEvent.location);
  }
  if (pastEvent.practitionerName) {
    secondaryTextParts.push(pastEvent.practitionerName);
  }
  const secondaryText = secondaryTextParts.join(' | ');

  const isStatic = !linkToTertiaryPage;
  const onClick = linkToTertiaryPage
    ? () => {
        window.location.href = `${tertiaryPagePrefix}/${pastEvent.name}`;
      }
    : () => {};

  return (
    <ListItem
      primaryText={primaryTextParts.join(' • ')}
      secondaryText={secondaryText}
      isStatic={isStatic}
      onClick={onClick}
    />
  );
}
