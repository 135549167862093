import {ButtonBase, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';
import {AUTOMATION_IDS} from '../../common/automationIDs';
import PastEventPreviewCard from '../PastEventPreviewCard/PastEventPreviewCard';
import styles from './PastEventsSectionContent.module.css';

type PastEventsSectionContentProps = {
  onClick: () => void;
  isLoading: boolean;
  pastEvents: PastEvent[];
};

export default function PastEventsSectionContent(
  props: PastEventsSectionContentProps
) {
  const theme = useTheme();
  const {t} = useTranslation();

  const {onClick, isLoading, pastEvents} = props;

  return (
    <ButtonBase
      onClick={onClick}
      className={styles.pastEventsContainer}
      component="div"
    >
      {isLoading ? (
        <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
          <PillSkeleton
            width={theme.spacing(17.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <div>
          <Typography
            component={'h2'}
            sx={{marginBottom: '20px'}}
            variant="display6"
          >
            {t('past-events')}
          </Typography>
          {pastEvents.length === 0 ? (
            <div data-testid={AUTOMATION_IDS.NO_PAST_EVENTS_MESSAGE}>
              <Typography>{t('no-past-events')}</Typography>
            </div>
          ) : (
            <Box sx={{width: '100%'}}>
              <Stack
                data-testid={`${AUTOMATION_IDS.LANDING_PAGE.LATEST_ENCOUNTERS}`}
                spacing={1}
              >
                {pastEvents.map(pastEvent => (
                  <PastEventPreviewCard
                    key={pastEvent.name}
                    pastEvent={pastEvent}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </div>
      )}
    </ButtonBase>
  );
}
