import {ButtonBase, ThemeProvider, Typography, useTheme} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  List,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import {secondaryPageRoute} from '../../../Root';
import PastEventsService from '../../../services/PastEventsService';
import {AUTOMATION_IDS} from '../../automationIDs';
import PastVisitCard from '../../components/PastEventCard/PastEventCard';
import styles from './PastEventsPrimaryCard.module.css';

const RELOAD_INTERVAL_MS = 5000;

type PastEventsPageContentProps = {
  onClick: () => void;
  isLoading: boolean;
  pastEvents: PastEvent[];
};

// Exported for testing
export default function PastEventsPrimaryCardContent(
  props: PastEventsPageContentProps
) {
  const theme = useTheme();
  const {t} = useTranslation();

  const {onClick, isLoading, pastEvents} = props;

  return (
    <ButtonBase
      onClick={onClick}
      className={`${styles.pastEventsContainer} ${
        isLoading || pastEvents.length === 0
          ? `${styles.emptyPastEventsContainer}`
          : ''
      }`}
      component="div"
    >
      {isLoading ? (
        <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
          <PillSkeleton
            width={theme.spacing(17.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <div>
          <Typography component={'h2'} variant="display6">
            {t('past-events')}
          </Typography>
          {pastEvents.length === 0 ? (
            <div
              data-testid={AUTOMATION_IDS.NO_PAST_EVENTS_MESSAGE}
              style={{marginTop: '20px'}}
            >
              <Typography>{t('no-past-events')}</Typography>
            </div>
          ) : (
            <div
              data-testid={`${AUTOMATION_IDS.LANDING_PAGE.LATEST_ENCOUNTERS}`}
            >
              <List style={{paddingLeft: '0px'}}>
                {pastEvents.map(pastEvent => (
                  <PastVisitCard
                    key={pastEvent.name}
                    pastEvent={pastEvent}
                    linkToTertiaryPage={false}
                  />
                ))}
              </List>
            </div>
          )}
        </div>
      )}
    </ButtonBase>
  );
}

export function PastEventsPrimaryCard() {
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoading(true);
    const {cached, fresh} = PastEventsService.getLatestEncountersDetails();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updatePastEvents(cached.pastEvents);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updatePastEvents(resp.pastEvents))
      .catch(err => {
        //logError(err);

        //if (!isAuthenticationError(err)) {
        // Attempt to load the profile data again in 5 seconds
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoading(false);
        //}
      });
  }

  const updatePastEvents = (pastEvents: PastEvent[]) => {
    setPastEvents(pastEvents);
    setIsLoading(false);
  };

  const onCardClick = async () => {
    if (pastEvents.length === 0 || isLoading) return;
    const newUrl = new URL(window.location.href);
    newUrl.pathname = secondaryPageRoute;

    navigateToUrl(newUrl.toString());
  };

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <PastEventsPrimaryCardContent
        onClick={onCardClick}
        isLoading={isLoading}
        pastEvents={pastEvents}
      />
    </ThemeProvider>
  );
}
