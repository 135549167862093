/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "phr/past-events/bff/api/v1/past-events_bff.proto" (package "phr.past_events.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// (-- api-linter: core::0191::proto-package=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
// (-- api-linter: core::0191::filenames=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
//
import { ProceduresBFFService } from "./past-events_bff";
import type { GetAllProceduresResponse } from "./past-events_bff";
import type { GetAllProceduresRequest } from "./past-events_bff";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PastEventsBFFService } from "./past-events_bff";
import type { GetAllPastEventDetailsResponse } from "./past-events_bff";
import type { GetAllPastEventDetailsRequest } from "./past-events_bff";
import type { GetSinglePastEventDetailResponse } from "./past-events_bff";
import type { GetSinglePastEventDetailRequest } from "./past-events_bff";
import type { GetSinglePastEventResponse } from "./past-events_bff";
import type { GetSinglePastEventRequest } from "./past-events_bff";
import type { GetAllImmunizationsResponse } from "./past-events_bff";
import type { GetAllImmunizationsRequest } from "./past-events_bff";
import type { GetAllEncountersResponse } from "./past-events_bff";
import type { GetAllEncountersRequest } from "./past-events_bff";
import type { GetAllPatientsResponse } from "./past-events_bff";
import type { GetAllPatientsRequest } from "./past-events_bff";
import type { GetAllVaccinationsResponse } from "./past-events_bff";
import type { GetAllVaccinationsRequest } from "./past-events_bff";
import type { GetAllPractitionersResponse } from "./past-events_bff";
import type { GetAllPractitionersRequest } from "./past-events_bff";
import type { GetAllObservationsResponse } from "./past-events_bff";
import type { GetAllObservationsRequest } from "./past-events_bff";
import type { GetAllConditionsResponse } from "./past-events_bff";
import type { GetAllConditionsRequest } from "./past-events_bff";
import type { GetAllEncountersDetailsRequest } from "./past-events_bff";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetEncounterDetailsListResponse } from "./past-events_bff";
import type { GetLatestEncountersDetailsRequest } from "./past-events_bff";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export interface IPastEventsBFFServiceClient {
    /**
     * Get full details of N most recent encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by passing the logged in user’s PersonID (extracted from CIAM).
     * - Encounter - BFF communicates with the PHR Backend to read Encounter resources, which is the FHIR resource that stores the Past Events Data.
     *
     * @generated from protobuf rpc: GetLatestEncountersDetails(phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getLatestEncountersDetails(input: GetLatestEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse>;
    /**
     * Get full details of all encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Encounter - BFF communicates with the PHR Backend to read Encounter resources, which is the FHIR resource that stores the Past Events Data.
     *
     * @generated from protobuf rpc: GetAllEncountersDetails(phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getAllEncountersDetails(input: GetAllEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse>;
    /**
     * Get full list of all conditions
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Conditions - BFF communicates with the PHR Backend to read Condition resources
     *
     * @generated from protobuf rpc: GetAllConditions(phr.past_events.bff.api.v1.GetAllConditionsRequest) returns (phr.past_events.bff.api.v1.GetAllConditionsResponse);
     */
    getAllConditions(input: GetAllConditionsRequest, options?: RpcOptions): UnaryCall<GetAllConditionsRequest, GetAllConditionsResponse>;
    /**
     * Get full list of all observations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Observations - BFF communicates with the PHR Backend to read Observations resources
     *
     * @generated from protobuf rpc: GetAllObservations(phr.past_events.bff.api.v1.GetAllObservationsRequest) returns (phr.past_events.bff.api.v1.GetAllObservationsResponse);
     */
    getAllObservations(input: GetAllObservationsRequest, options?: RpcOptions): UnaryCall<GetAllObservationsRequest, GetAllObservationsResponse>;
    /**
     * Get full list of all practitioners
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Practitioners - BFF communicates with the PHR Backend to read Practitioner resources
     *
     * @generated from protobuf rpc: GetAllPractitioners(phr.past_events.bff.api.v1.GetAllPractitionersRequest) returns (phr.past_events.bff.api.v1.GetAllPractitionersResponse);
     */
    getAllPractitioners(input: GetAllPractitionersRequest, options?: RpcOptions): UnaryCall<GetAllPractitionersRequest, GetAllPractitionersResponse>;
    /**
     * Get all of the patient’s vaccinations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Vaccinations - BFF communicates with the PHR Backend to read Vaccination resources
     *
     * @generated from protobuf rpc: GetAllVaccinations(phr.past_events.bff.api.v1.GetAllVaccinationsRequest) returns (phr.past_events.bff.api.v1.GetAllVaccinationsResponse);
     */
    getAllVaccinations(input: GetAllVaccinationsRequest, options?: RpcOptions): UnaryCall<GetAllVaccinationsRequest, GetAllVaccinationsResponse>;
    /**
     * Get full list of all patients
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Patients - BFF communicates with the PHR Backend to read Patient resources
     *
     * @generated from protobuf rpc: GetAllPatients(phr.past_events.bff.api.v1.GetAllPatientsRequest) returns (phr.past_events.bff.api.v1.GetAllPatientsResponse);
     */
    getAllPatients(input: GetAllPatientsRequest, options?: RpcOptions): UnaryCall<GetAllPatientsRequest, GetAllPatientsResponse>;
    /**
     * Get full list of all encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Encounters - BFF communicates with the PHR Backend to read Encounter resources
     *
     * @generated from protobuf rpc: GetAllEncounters(phr.past_events.bff.api.v1.GetAllEncountersRequest) returns (phr.past_events.bff.api.v1.GetAllEncountersResponse);
     */
    getAllEncounters(input: GetAllEncountersRequest, options?: RpcOptions): UnaryCall<GetAllEncountersRequest, GetAllEncountersResponse>;
    /**
     * Get full list of all immunizations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Immunizations - BFF communicates with the PHR Backend to read Immunization resources
     *
     * @generated from protobuf rpc: GetAllImmunizations(phr.past_events.bff.api.v1.GetAllImmunizationsRequest) returns (phr.past_events.bff.api.v1.GetAllImmunizationsResponse);
     */
    getAllImmunizations(input: GetAllImmunizationsRequest, options?: RpcOptions): UnaryCall<GetAllImmunizationsRequest, GetAllImmunizationsResponse>;
    /**
     * Get one single PastEvent
     * FHIR Interactions:
     * - Encounter - BFF communicates with the PHR Backend to read a Encounter resource
     *
     * @generated from protobuf rpc: GetSinglePastEvent(phr.past_events.bff.api.v1.GetSinglePastEventRequest) returns (phr.past_events.bff.api.v1.GetSinglePastEventResponse);
     */
    getSinglePastEvent(input: GetSinglePastEventRequest, options?: RpcOptions): UnaryCall<GetSinglePastEventRequest, GetSinglePastEventResponse>;
    /**
     * Get one single PastEventDetail
     * FHIR Interactions:
     * - DocumentReference - BFF communicates with the PHR Backend to read a DocumentReference resource
     *
     * @generated from protobuf rpc: GetSinglePastEventDetail(phr.past_events.bff.api.v1.GetSinglePastEventDetailRequest) returns (phr.past_events.bff.api.v1.GetSinglePastEventDetailResponse);
     */
    getSinglePastEventDetail(input: GetSinglePastEventDetailRequest, options?: RpcOptions): UnaryCall<GetSinglePastEventDetailRequest, GetSinglePastEventDetailResponse>;
    /**
     * Get all PastEventDetails
     * FHIR Interactions:
     * - DocumentReferences - BFF communicates with the PHR Backend to read DocumentReference resources
     *
     * @generated from protobuf rpc: GetAllPastEventDetails(phr.past_events.bff.api.v1.GetAllPastEventDetailsRequest) returns (phr.past_events.bff.api.v1.GetAllPastEventDetailsResponse);
     */
    getAllPastEventDetails(input: GetAllPastEventDetailsRequest, options?: RpcOptions): UnaryCall<GetAllPastEventDetailsRequest, GetAllPastEventDetailsResponse>;
}
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export class PastEventsBFFServiceClient implements IPastEventsBFFServiceClient, ServiceInfo {
    typeName = PastEventsBFFService.typeName;
    methods = PastEventsBFFService.methods;
    options = PastEventsBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get full details of N most recent encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by passing the logged in user’s PersonID (extracted from CIAM).
     * - Encounter - BFF communicates with the PHR Backend to read Encounter resources, which is the FHIR resource that stores the Past Events Data.
     *
     * @generated from protobuf rpc: GetLatestEncountersDetails(phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getLatestEncountersDetails(input: GetLatestEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full details of all encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Encounter - BFF communicates with the PHR Backend to read Encounter resources, which is the FHIR resource that stores the Past Events Data.
     *
     * @generated from protobuf rpc: GetAllEncountersDetails(phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getAllEncountersDetails(input: GetAllEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all conditions
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Conditions - BFF communicates with the PHR Backend to read Condition resources
     *
     * @generated from protobuf rpc: GetAllConditions(phr.past_events.bff.api.v1.GetAllConditionsRequest) returns (phr.past_events.bff.api.v1.GetAllConditionsResponse);
     */
    getAllConditions(input: GetAllConditionsRequest, options?: RpcOptions): UnaryCall<GetAllConditionsRequest, GetAllConditionsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllConditionsRequest, GetAllConditionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all observations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Observations - BFF communicates with the PHR Backend to read Observations resources
     *
     * @generated from protobuf rpc: GetAllObservations(phr.past_events.bff.api.v1.GetAllObservationsRequest) returns (phr.past_events.bff.api.v1.GetAllObservationsResponse);
     */
    getAllObservations(input: GetAllObservationsRequest, options?: RpcOptions): UnaryCall<GetAllObservationsRequest, GetAllObservationsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllObservationsRequest, GetAllObservationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all practitioners
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Practitioners - BFF communicates with the PHR Backend to read Practitioner resources
     *
     * @generated from protobuf rpc: GetAllPractitioners(phr.past_events.bff.api.v1.GetAllPractitionersRequest) returns (phr.past_events.bff.api.v1.GetAllPractitionersResponse);
     */
    getAllPractitioners(input: GetAllPractitionersRequest, options?: RpcOptions): UnaryCall<GetAllPractitionersRequest, GetAllPractitionersResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllPractitionersRequest, GetAllPractitionersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get all of the patient’s vaccinations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Vaccinations - BFF communicates with the PHR Backend to read Vaccination resources
     *
     * @generated from protobuf rpc: GetAllVaccinations(phr.past_events.bff.api.v1.GetAllVaccinationsRequest) returns (phr.past_events.bff.api.v1.GetAllVaccinationsResponse);
     */
    getAllVaccinations(input: GetAllVaccinationsRequest, options?: RpcOptions): UnaryCall<GetAllVaccinationsRequest, GetAllVaccinationsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllVaccinationsRequest, GetAllVaccinationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all patients
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Patients - BFF communicates with the PHR Backend to read Patient resources
     *
     * @generated from protobuf rpc: GetAllPatients(phr.past_events.bff.api.v1.GetAllPatientsRequest) returns (phr.past_events.bff.api.v1.GetAllPatientsResponse);
     */
    getAllPatients(input: GetAllPatientsRequest, options?: RpcOptions): UnaryCall<GetAllPatientsRequest, GetAllPatientsResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllPatientsRequest, GetAllPatientsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all encounters
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Encounters - BFF communicates with the PHR Backend to read Encounter resources
     *
     * @generated from protobuf rpc: GetAllEncounters(phr.past_events.bff.api.v1.GetAllEncountersRequest) returns (phr.past_events.bff.api.v1.GetAllEncountersResponse);
     */
    getAllEncounters(input: GetAllEncountersRequest, options?: RpcOptions): UnaryCall<GetAllEncountersRequest, GetAllEncountersResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllEncountersRequest, GetAllEncountersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full list of all immunizations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Immunizations - BFF communicates with the PHR Backend to read Immunization resources
     *
     * @generated from protobuf rpc: GetAllImmunizations(phr.past_events.bff.api.v1.GetAllImmunizationsRequest) returns (phr.past_events.bff.api.v1.GetAllImmunizationsResponse);
     */
    getAllImmunizations(input: GetAllImmunizationsRequest, options?: RpcOptions): UnaryCall<GetAllImmunizationsRequest, GetAllImmunizationsResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllImmunizationsRequest, GetAllImmunizationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get one single PastEvent
     * FHIR Interactions:
     * - Encounter - BFF communicates with the PHR Backend to read a Encounter resource
     *
     * @generated from protobuf rpc: GetSinglePastEvent(phr.past_events.bff.api.v1.GetSinglePastEventRequest) returns (phr.past_events.bff.api.v1.GetSinglePastEventResponse);
     */
    getSinglePastEvent(input: GetSinglePastEventRequest, options?: RpcOptions): UnaryCall<GetSinglePastEventRequest, GetSinglePastEventResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSinglePastEventRequest, GetSinglePastEventResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get one single PastEventDetail
     * FHIR Interactions:
     * - DocumentReference - BFF communicates with the PHR Backend to read a DocumentReference resource
     *
     * @generated from protobuf rpc: GetSinglePastEventDetail(phr.past_events.bff.api.v1.GetSinglePastEventDetailRequest) returns (phr.past_events.bff.api.v1.GetSinglePastEventDetailResponse);
     */
    getSinglePastEventDetail(input: GetSinglePastEventDetailRequest, options?: RpcOptions): UnaryCall<GetSinglePastEventDetailRequest, GetSinglePastEventDetailResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSinglePastEventDetailRequest, GetSinglePastEventDetailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get all PastEventDetails
     * FHIR Interactions:
     * - DocumentReferences - BFF communicates with the PHR Backend to read DocumentReference resources
     *
     * @generated from protobuf rpc: GetAllPastEventDetails(phr.past_events.bff.api.v1.GetAllPastEventDetailsRequest) returns (phr.past_events.bff.api.v1.GetAllPastEventDetailsResponse);
     */
    getAllPastEventDetails(input: GetAllPastEventDetailsRequest, options?: RpcOptions): UnaryCall<GetAllPastEventDetailsRequest, GetAllPastEventDetailsResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllPastEventDetailsRequest, GetAllPastEventDetailsResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.ProceduresBFFService
 */
export interface IProceduresBFFServiceClient {
    /**
     * Get full details of all procedures
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Procedure - BFF communicates with the PHR Backend to read Procedure resources, which is the FHIR resource that stores the Procedure Data.
     *
     * @generated from protobuf rpc: GetAllProcedures(phr.past_events.bff.api.v1.GetAllProceduresRequest) returns (phr.past_events.bff.api.v1.GetAllProceduresResponse);
     */
    getAllProcedures(input: GetAllProceduresRequest, options?: RpcOptions): UnaryCall<GetAllProceduresRequest, GetAllProceduresResponse>;
}
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.ProceduresBFFService
 */
export class ProceduresBFFServiceClient implements IProceduresBFFServiceClient, ServiceInfo {
    typeName = ProceduresBFFService.typeName;
    methods = ProceduresBFFService.methods;
    options = ProceduresBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get full details of all procedures
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Procedure - BFF communicates with the PHR Backend to read Procedure resources, which is the FHIR resource that stores the Procedure Data.
     *
     * @generated from protobuf rpc: GetAllProcedures(phr.past_events.bff.api.v1.GetAllProceduresRequest) returns (phr.past_events.bff.api.v1.GetAllProceduresResponse);
     */
    getAllProcedures(input: GetAllProceduresRequest, options?: RpcOptions): UnaryCall<GetAllProceduresRequest, GetAllProceduresResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllProceduresRequest, GetAllProceduresResponse>("unary", this._transport, method, opt, input);
    }
}
