import {
  Card,
  CardActionArea,
  CardContent,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {useEffect, useState} from 'react';

import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import dogfoodIcon from '../../assets/dogfood.svg';

export function RawHealthDataCard() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {t} = useTranslation();

  const enabled = config.getBoolean('FEATURE_RAW_HEALTH_RECORDS_ENABLED');

  useEffect(() => {
    if (!enabled) return;

    loadData(true);
  }, [enabled]);

  async function loadData(_initialLoad?: boolean) {
    setIsLoading(false);
    return;
  }

  const onCardClick = async () => {
    if (isLoading) return;
    const newUrl = new URL(window.location.href);
    newUrl.pathname = '/me/mobile/health-dashboard/raw-data';

    navigateToUrl(newUrl.toString());
  };

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  if (!enabled) return null;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <Card>
        <CardActionArea onClick={onCardClick}>
          <CardContent>
            {isLoading ? (
              <div
                style={{marginBottom: '20px'}}
                role="status"
                aria-busy={true}
              >
                <PillSkeleton
                  width={theme.spacing(17.5)}
                  height={theme.spacing(3.5)}
                />
              </div>
            ) : (
              <>
                <div
                  style={{display: 'flex', alignItems: 'center', gap: '8px'}}
                >
                  <img
                    src={dogfoodIcon}
                    alt={'Dogfood icon'}
                    width="36px"
                    height="36px"
                  />
                  <Typography component={'h2'} variant="h6">
                    {t('raw-data-card-header')}
                  </Typography>
                </div>
                <Typography>Dogfood only</Typography>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
  );
}
