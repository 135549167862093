/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "phr/past-events/bff/api/v1/past-events_bff.proto" (package "phr.past_events.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// (-- api-linter: core::0191::proto-package=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
// (-- api-linter: core::0191::filenames=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ProcedureDetail } from "../../../../backend/api/v1/phr_service";
import { PastEventDetail } from "../../../../backend/api/v1/phr_service";
import { RawImmunization } from "../../../../backend/api/v1/phr_service";
import { RawEncounter } from "../../../../backend/api/v1/phr_service";
import { Patient } from "../../../../backend/api/v1/phr_service";
import { Vaccination } from "../../../../backend/api/v1/phr_service";
import { Practitioner } from "../../../../backend/api/v1/phr_service";
import { Observation } from "../../../../backend/api/v1/phr_service";
import { Condition } from "../../../../backend/api/v1/phr_service";
import { PastEvent } from "../../../../backend/api/v1/phr_service";
/**
 * Request to get the N most recent encounters for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest
 */
export interface GetLatestEncountersDetailsRequest {
    /**
     * The number of encounters to display in the snapshot, likely configured to be 3
     * (-- api-linter: core::0141::count-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: int32 num_encounters = 1;
     */
    numEncounters: number;
}
/**
 * Request to get all encounters for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest
 */
export interface GetAllEncountersDetailsRequest {
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListEncounters` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListEncounters` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Represents all the relevant metadata of an Encounter FHIR Resource to be
 * displayed in both the Past Events Landing page and the Past Events Section
 *
 * @deprecated
 * @generated from protobuf message phr.past_events.bff.api.v1.EncounterDetails
 */
export interface EncounterDetails {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The start time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string start_time = 2;
     */
    startTime: string;
    /**
     * The end time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string end_time = 11;
     */
    endTime: string;
    /**
     * The name of the Practitioner from Zus FHIR
     * (-- api-linter: core::0122::name-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string practitioner_name = 3;
     */
    practitionerName: string;
    /**
     * The specialty of the Practitioner from Zus FHIR
     *
     * @generated from protobuf field: string practitioner_specialty = 4;
     */
    practitionerSpecialty: string;
    /**
     * The encounter type: (TBD - https://www.hl7.org/fhir/valueset-encounter-type.html)
     *
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * The encounter classification: (TBD - https://terminology.hl7.org/5.1.0/ValueSet-encounter-class.html)
     *
     * @generated from protobuf field: string classification = 6;
     */
    classification: string;
    /**
     * The organization where encounter took place (TBD - Encounter.location.managingOrganization.name)
     *
     * @generated from protobuf field: string organization = 7;
     */
    organization: string;
    /**
     * The location of the organization where encounter took place (TBD - Encounter.location.display)
     *
     * @generated from protobuf field: string location = 8;
     */
    location: string;
    /**
     * The reason for the encounter
     *
     * @generated from protobuf field: string reason = 9;
     */
    reason: string;
    /**
     * Provider notes from the visit (TBD on if this is needed)
     *
     * @generated from protobuf field: string visit_notes = 10;
     */
    visitNotes: string;
}
/**
 * Response containing the list of EncounterDetails.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetEncounterDetailsListResponse
 */
export interface GetEncounterDetailsListResponse {
    /**
     * The list of EncounterDetails.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.PastEvent past_events = 2;
     */
    pastEvents: PastEvent[];
}
/**
 * Request to get all conditions for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllConditionsRequest
 */
export interface GetAllConditionsRequest {
    /**
     * The maximum number of conditions to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 conditions will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllConditions` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllConditions` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Conditions.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllConditionsResponse
 */
export interface GetAllConditionsResponse {
    /**
     * The list of Conditions.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Condition conditions = 1;
     */
    conditions: Condition[];
}
/**
 * Request to get all observations for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllObservationsRequest
 */
export interface GetAllObservationsRequest {
    /**
     * The maximum number of observations to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 observations will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllObservations` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllObservations` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Observations.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllObservationsResponse
 */
export interface GetAllObservationsResponse {
    /**
     * The list of Observations.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Observation observations = 1;
     */
    observations: Observation[];
}
/**
 * Request to get all practitioners for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPractitionersRequest
 */
export interface GetAllPractitionersRequest {
    /**
     * The maximum number of practitioners to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 practitioners will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllPractitioners` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllPractitioners` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Practitioners.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPractitionersResponse
 */
export interface GetAllPractitionersResponse {
    /**
     * The list of Practitioners.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Practitioner practitioners = 1;
     */
    practitioners: Practitioner[];
}
/**
 * Response containing the list of Vaccinations for the current participant
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllVaccinationsRequest
 */
export interface GetAllVaccinationsRequest {
    /**
     * The maximum number of Vaccinations to return. The service may return fewer
     * than this value.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous
     * `GetAllVaccinationsRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters
     * provided to `GetAllVaccinationsRequest` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of vaccinations
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllVaccinationsResponse
 */
export interface GetAllVaccinationsResponse {
    /**
     * List of vaccinations
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Vaccination vaccinations = 1;
     */
    vaccinations: Vaccination[];
}
/**
 * Request to get all patients for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPatientsRequest
 */
export interface GetAllPatientsRequest {
    /**
     * The maximum number of patients to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 patients will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllPatients` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllPatients` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Patients.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPatientsResponse
 */
export interface GetAllPatientsResponse {
    /**
     * The list of Patients.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Patient patients = 1;
     */
    patients: Patient[];
}
/**
 * Request to get all encounters for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllEncountersRequest
 */
export interface GetAllEncountersRequest {
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 encounters will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllEncounters` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllEncounters` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Encounters.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllEncountersResponse
 */
export interface GetAllEncountersResponse {
    /**
     * The list of Encounters.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.RawEncounter encounters = 1;
     */
    encounters: RawEncounter[];
}
/**
 * Request to get all immunizations for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllImmunizationsRequest
 */
export interface GetAllImmunizationsRequest {
    /**
     * The maximum number of immunizations to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 immunizations will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllImmunizations` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllImmunizations` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Immunizations.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllImmunizationsResponse
 */
export interface GetAllImmunizationsResponse {
    /**
     * The list of Immunizations.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.RawImmunization immunizations = 1;
     */
    immunizations: RawImmunization[];
}
/**
 * Request to get a singular PastEvent
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetSinglePastEventRequest
 */
export interface GetSinglePastEventRequest {
    /**
     * The ID of the FHIR Encounter
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * Response containing a single PastEvent
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetSinglePastEventResponse
 */
export interface GetSinglePastEventResponse {
    /**
     * A singular PastEvent
     *
     * @generated from protobuf field: phr.backend.api.v1.PastEvent past_event = 1;
     */
    pastEvent?: PastEvent;
}
/**
 * Request to get a singular PastEventDetail
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetSinglePastEventDetailRequest
 */
export interface GetSinglePastEventDetailRequest {
    /**
     * The ID of the FHIR DocumentReference
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * Response containing a single PastEventDetail
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetSinglePastEventDetailResponse
 */
export interface GetSinglePastEventDetailResponse {
    /**
     * A singular PastEventDetail
     *
     * @generated from protobuf field: phr.backend.api.v1.PastEventDetail past_event_detail = 1;
     */
    pastEventDetail?: PastEventDetail;
}
/**
 * Request to get all PastEventDetails
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPastEventDetailsRequest
 */
export interface GetAllPastEventDetailsRequest {
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 encounters will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListEncounters` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListEncounters` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * The ID of the FHIR Encounter
     *
     * @generated from protobuf field: string encounter_id = 3;
     */
    encounterId: string;
}
/**
 * Response containing the list of all of the PastEventDetails.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllPastEventDetailsResponse
 */
export interface GetAllPastEventDetailsResponse {
    /**
     * The list of PastEventDetails
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.PastEventDetail past_event_details = 1;
     */
    pastEventDetails: PastEventDetail[];
}
/**
 * Request to get all procedures for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllProceduresRequest
 */
export interface GetAllProceduresRequest {
    /**
     * The maximum number of procedures to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 procedures will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllProcedures` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllProcedures` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of Procedures.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllProceduresResponse
 */
export interface GetAllProceduresResponse {
    /**
     * The list of ProcedureDetails.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.ProcedureDetail procedure_details = 1;
     */
    procedureDetails: ProcedureDetail[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestEncountersDetailsRequest$Type extends MessageType<GetLatestEncountersDetailsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest", [
            { no: 1, name: "num_encounters", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetLatestEncountersDetailsRequest>): GetLatestEncountersDetailsRequest {
        const message = { numEncounters: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLatestEncountersDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLatestEncountersDetailsRequest): GetLatestEncountersDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 num_encounters */ 1:
                    message.numEncounters = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLatestEncountersDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 num_encounters = 1; */
        if (message.numEncounters !== 0)
            writer.tag(1, WireType.Varint).int32(message.numEncounters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest
 */
export const GetLatestEncountersDetailsRequest = new GetLatestEncountersDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersDetailsRequest$Type extends MessageType<GetAllEncountersDetailsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersDetailsRequest>): GetAllEncountersDetailsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersDetailsRequest): GetAllEncountersDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest
 */
export const GetAllEncountersDetailsRequest = new GetAllEncountersDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EncounterDetails$Type extends MessageType<EncounterDetails> {
    constructor() {
        super("phr.past_events.bff.api.v1.EncounterDetails", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "practitioner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "practitioner_specialty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "classification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "visit_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "google.api.resource": { type: "verily.health/Encounter", pattern: ["encounter/{encounter_id}"], plural: "encounters", singular: "encounter" } });
    }
    create(value?: PartialMessage<EncounterDetails>): EncounterDetails {
        const message = { name: "", startTime: "", endTime: "", practitionerName: "", practitionerSpecialty: "", type: "", classification: "", organization: "", location: "", reason: "", visitNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EncounterDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EncounterDetails): EncounterDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string start_time */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 11:
                    message.endTime = reader.string();
                    break;
                case /* string practitioner_name */ 3:
                    message.practitionerName = reader.string();
                    break;
                case /* string practitioner_specialty */ 4:
                    message.practitionerSpecialty = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                case /* string classification */ 6:
                    message.classification = reader.string();
                    break;
                case /* string organization */ 7:
                    message.organization = reader.string();
                    break;
                case /* string location */ 8:
                    message.location = reader.string();
                    break;
                case /* string reason */ 9:
                    message.reason = reader.string();
                    break;
                case /* string visit_notes */ 10:
                    message.visitNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EncounterDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string start_time = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 11; */
        if (message.endTime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.endTime);
        /* string practitioner_name = 3; */
        if (message.practitionerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.practitionerName);
        /* string practitioner_specialty = 4; */
        if (message.practitionerSpecialty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.practitionerSpecialty);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string classification = 6; */
        if (message.classification !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classification);
        /* string organization = 7; */
        if (message.organization !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.organization);
        /* string location = 8; */
        if (message.location !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.location);
        /* string reason = 9; */
        if (message.reason !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.reason);
        /* string visit_notes = 10; */
        if (message.visitNotes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.visitNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.EncounterDetails
 */
export const EncounterDetails = new EncounterDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEncounterDetailsListResponse$Type extends MessageType<GetEncounterDetailsListResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetEncounterDetailsListResponse", [
            { no: 2, name: "past_events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PastEvent }
        ]);
    }
    create(value?: PartialMessage<GetEncounterDetailsListResponse>): GetEncounterDetailsListResponse {
        const message = { pastEvents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetEncounterDetailsListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEncounterDetailsListResponse): GetEncounterDetailsListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.PastEvent past_events */ 2:
                    message.pastEvents.push(PastEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEncounterDetailsListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.PastEvent past_events = 2; */
        for (let i = 0; i < message.pastEvents.length; i++)
            PastEvent.internalBinaryWrite(message.pastEvents[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetEncounterDetailsListResponse
 */
export const GetEncounterDetailsListResponse = new GetEncounterDetailsListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllConditionsRequest$Type extends MessageType<GetAllConditionsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllConditionsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllConditionsRequest>): GetAllConditionsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllConditionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllConditionsRequest): GetAllConditionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllConditionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllConditionsRequest
 */
export const GetAllConditionsRequest = new GetAllConditionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllConditionsResponse$Type extends MessageType<GetAllConditionsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllConditionsResponse", [
            { no: 1, name: "conditions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Condition }
        ]);
    }
    create(value?: PartialMessage<GetAllConditionsResponse>): GetAllConditionsResponse {
        const message = { conditions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllConditionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllConditionsResponse): GetAllConditionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Condition conditions */ 1:
                    message.conditions.push(Condition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllConditionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Condition conditions = 1; */
        for (let i = 0; i < message.conditions.length; i++)
            Condition.internalBinaryWrite(message.conditions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllConditionsResponse
 */
export const GetAllConditionsResponse = new GetAllConditionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllObservationsRequest$Type extends MessageType<GetAllObservationsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllObservationsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllObservationsRequest>): GetAllObservationsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllObservationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllObservationsRequest): GetAllObservationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllObservationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllObservationsRequest
 */
export const GetAllObservationsRequest = new GetAllObservationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllObservationsResponse$Type extends MessageType<GetAllObservationsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllObservationsResponse", [
            { no: 1, name: "observations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Observation }
        ]);
    }
    create(value?: PartialMessage<GetAllObservationsResponse>): GetAllObservationsResponse {
        const message = { observations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllObservationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllObservationsResponse): GetAllObservationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Observation observations */ 1:
                    message.observations.push(Observation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllObservationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Observation observations = 1; */
        for (let i = 0; i < message.observations.length; i++)
            Observation.internalBinaryWrite(message.observations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllObservationsResponse
 */
export const GetAllObservationsResponse = new GetAllObservationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPractitionersRequest$Type extends MessageType<GetAllPractitionersRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPractitionersRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllPractitionersRequest>): GetAllPractitionersRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPractitionersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPractitionersRequest): GetAllPractitionersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPractitionersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPractitionersRequest
 */
export const GetAllPractitionersRequest = new GetAllPractitionersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPractitionersResponse$Type extends MessageType<GetAllPractitionersResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPractitionersResponse", [
            { no: 1, name: "practitioners", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Practitioner }
        ]);
    }
    create(value?: PartialMessage<GetAllPractitionersResponse>): GetAllPractitionersResponse {
        const message = { practitioners: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPractitionersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPractitionersResponse): GetAllPractitionersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Practitioner practitioners */ 1:
                    message.practitioners.push(Practitioner.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPractitionersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Practitioner practitioners = 1; */
        for (let i = 0; i < message.practitioners.length; i++)
            Practitioner.internalBinaryWrite(message.practitioners[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPractitionersResponse
 */
export const GetAllPractitionersResponse = new GetAllPractitionersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllVaccinationsRequest$Type extends MessageType<GetAllVaccinationsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllVaccinationsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllVaccinationsRequest>): GetAllVaccinationsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllVaccinationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllVaccinationsRequest): GetAllVaccinationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllVaccinationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllVaccinationsRequest
 */
export const GetAllVaccinationsRequest = new GetAllVaccinationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllVaccinationsResponse$Type extends MessageType<GetAllVaccinationsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllVaccinationsResponse", [
            { no: 1, name: "vaccinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Vaccination }
        ]);
    }
    create(value?: PartialMessage<GetAllVaccinationsResponse>): GetAllVaccinationsResponse {
        const message = { vaccinations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllVaccinationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllVaccinationsResponse): GetAllVaccinationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Vaccination vaccinations */ 1:
                    message.vaccinations.push(Vaccination.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllVaccinationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Vaccination vaccinations = 1; */
        for (let i = 0; i < message.vaccinations.length; i++)
            Vaccination.internalBinaryWrite(message.vaccinations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllVaccinationsResponse
 */
export const GetAllVaccinationsResponse = new GetAllVaccinationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPatientsRequest$Type extends MessageType<GetAllPatientsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPatientsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllPatientsRequest>): GetAllPatientsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPatientsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPatientsRequest): GetAllPatientsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPatientsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPatientsRequest
 */
export const GetAllPatientsRequest = new GetAllPatientsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPatientsResponse$Type extends MessageType<GetAllPatientsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPatientsResponse", [
            { no: 1, name: "patients", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Patient }
        ]);
    }
    create(value?: PartialMessage<GetAllPatientsResponse>): GetAllPatientsResponse {
        const message = { patients: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPatientsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPatientsResponse): GetAllPatientsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Patient patients */ 1:
                    message.patients.push(Patient.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPatientsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Patient patients = 1; */
        for (let i = 0; i < message.patients.length; i++)
            Patient.internalBinaryWrite(message.patients[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPatientsResponse
 */
export const GetAllPatientsResponse = new GetAllPatientsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersRequest$Type extends MessageType<GetAllEncountersRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllEncountersRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersRequest>): GetAllEncountersRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersRequest): GetAllEncountersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllEncountersRequest
 */
export const GetAllEncountersRequest = new GetAllEncountersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersResponse$Type extends MessageType<GetAllEncountersResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllEncountersResponse", [
            { no: 1, name: "encounters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RawEncounter }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersResponse>): GetAllEncountersResponse {
        const message = { encounters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersResponse): GetAllEncountersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.RawEncounter encounters */ 1:
                    message.encounters.push(RawEncounter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.RawEncounter encounters = 1; */
        for (let i = 0; i < message.encounters.length; i++)
            RawEncounter.internalBinaryWrite(message.encounters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllEncountersResponse
 */
export const GetAllEncountersResponse = new GetAllEncountersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllImmunizationsRequest$Type extends MessageType<GetAllImmunizationsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllImmunizationsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllImmunizationsRequest>): GetAllImmunizationsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllImmunizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllImmunizationsRequest): GetAllImmunizationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllImmunizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllImmunizationsRequest
 */
export const GetAllImmunizationsRequest = new GetAllImmunizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllImmunizationsResponse$Type extends MessageType<GetAllImmunizationsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllImmunizationsResponse", [
            { no: 1, name: "immunizations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RawImmunization }
        ]);
    }
    create(value?: PartialMessage<GetAllImmunizationsResponse>): GetAllImmunizationsResponse {
        const message = { immunizations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllImmunizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllImmunizationsResponse): GetAllImmunizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.RawImmunization immunizations */ 1:
                    message.immunizations.push(RawImmunization.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllImmunizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.RawImmunization immunizations = 1; */
        for (let i = 0; i < message.immunizations.length; i++)
            RawImmunization.internalBinaryWrite(message.immunizations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllImmunizationsResponse
 */
export const GetAllImmunizationsResponse = new GetAllImmunizationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSinglePastEventRequest$Type extends MessageType<GetSinglePastEventRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetSinglePastEventRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSinglePastEventRequest>): GetSinglePastEventRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSinglePastEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSinglePastEventRequest): GetSinglePastEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSinglePastEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetSinglePastEventRequest
 */
export const GetSinglePastEventRequest = new GetSinglePastEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSinglePastEventResponse$Type extends MessageType<GetSinglePastEventResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetSinglePastEventResponse", [
            { no: 1, name: "past_event", kind: "message", T: () => PastEvent }
        ]);
    }
    create(value?: PartialMessage<GetSinglePastEventResponse>): GetSinglePastEventResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSinglePastEventResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSinglePastEventResponse): GetSinglePastEventResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.PastEvent past_event */ 1:
                    message.pastEvent = PastEvent.internalBinaryRead(reader, reader.uint32(), options, message.pastEvent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSinglePastEventResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.PastEvent past_event = 1; */
        if (message.pastEvent)
            PastEvent.internalBinaryWrite(message.pastEvent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetSinglePastEventResponse
 */
export const GetSinglePastEventResponse = new GetSinglePastEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSinglePastEventDetailRequest$Type extends MessageType<GetSinglePastEventDetailRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetSinglePastEventDetailRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSinglePastEventDetailRequest>): GetSinglePastEventDetailRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSinglePastEventDetailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSinglePastEventDetailRequest): GetSinglePastEventDetailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSinglePastEventDetailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetSinglePastEventDetailRequest
 */
export const GetSinglePastEventDetailRequest = new GetSinglePastEventDetailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSinglePastEventDetailResponse$Type extends MessageType<GetSinglePastEventDetailResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetSinglePastEventDetailResponse", [
            { no: 1, name: "past_event_detail", kind: "message", T: () => PastEventDetail }
        ]);
    }
    create(value?: PartialMessage<GetSinglePastEventDetailResponse>): GetSinglePastEventDetailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSinglePastEventDetailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSinglePastEventDetailResponse): GetSinglePastEventDetailResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.PastEventDetail past_event_detail */ 1:
                    message.pastEventDetail = PastEventDetail.internalBinaryRead(reader, reader.uint32(), options, message.pastEventDetail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSinglePastEventDetailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.PastEventDetail past_event_detail = 1; */
        if (message.pastEventDetail)
            PastEventDetail.internalBinaryWrite(message.pastEventDetail, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetSinglePastEventDetailResponse
 */
export const GetSinglePastEventDetailResponse = new GetSinglePastEventDetailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPastEventDetailsRequest$Type extends MessageType<GetAllPastEventDetailsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPastEventDetailsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "encounter_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAllPastEventDetailsRequest>): GetAllPastEventDetailsRequest {
        const message = { pageSize: 0, pageToken: "", encounterId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPastEventDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPastEventDetailsRequest): GetAllPastEventDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* string encounter_id */ 3:
                    message.encounterId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPastEventDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* string encounter_id = 3; */
        if (message.encounterId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.encounterId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPastEventDetailsRequest
 */
export const GetAllPastEventDetailsRequest = new GetAllPastEventDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllPastEventDetailsResponse$Type extends MessageType<GetAllPastEventDetailsResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllPastEventDetailsResponse", [
            { no: 1, name: "past_event_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PastEventDetail }
        ]);
    }
    create(value?: PartialMessage<GetAllPastEventDetailsResponse>): GetAllPastEventDetailsResponse {
        const message = { pastEventDetails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllPastEventDetailsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllPastEventDetailsResponse): GetAllPastEventDetailsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.PastEventDetail past_event_details */ 1:
                    message.pastEventDetails.push(PastEventDetail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllPastEventDetailsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.PastEventDetail past_event_details = 1; */
        for (let i = 0; i < message.pastEventDetails.length; i++)
            PastEventDetail.internalBinaryWrite(message.pastEventDetails[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllPastEventDetailsResponse
 */
export const GetAllPastEventDetailsResponse = new GetAllPastEventDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllProceduresRequest$Type extends MessageType<GetAllProceduresRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllProceduresRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "verily.privacy.annotations.scrub": false, "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllProceduresRequest>): GetAllProceduresRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllProceduresRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllProceduresRequest): GetAllProceduresRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllProceduresRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllProceduresRequest
 */
export const GetAllProceduresRequest = new GetAllProceduresRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllProceduresResponse$Type extends MessageType<GetAllProceduresResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllProceduresResponse", [
            { no: 1, name: "procedure_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProcedureDetail }
        ]);
    }
    create(value?: PartialMessage<GetAllProceduresResponse>): GetAllProceduresResponse {
        const message = { procedureDetails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllProceduresResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllProceduresResponse): GetAllProceduresResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.ProcedureDetail procedure_details */ 1:
                    message.procedureDetails.push(ProcedureDetail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllProceduresResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.ProcedureDetail procedure_details = 1; */
        for (let i = 0; i < message.procedureDetails.length; i++)
            ProcedureDetail.internalBinaryWrite(message.procedureDetails[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllProceduresResponse
 */
export const GetAllProceduresResponse = new GetAllProceduresResponse$Type();
/**
 * @generated ServiceType for protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export const PastEventsBFFService = new ServiceType("phr.past_events.bff.api.v1.PastEventsBFFService", [
    { name: "GetLatestEncountersDetails", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetLatestEncountersDetailsRequest, O: GetEncounterDetailsListResponse },
    { name: "GetAllEncountersDetails", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllEncountersDetailsRequest, O: GetEncounterDetailsListResponse },
    { name: "GetAllConditions", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllConditionsRequest, O: GetAllConditionsResponse },
    { name: "GetAllObservations", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllObservationsRequest, O: GetAllObservationsResponse },
    { name: "GetAllPractitioners", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllPractitionersRequest, O: GetAllPractitionersResponse },
    { name: "GetAllVaccinations", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllVaccinationsRequest, O: GetAllVaccinationsResponse },
    { name: "GetAllPatients", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllPatientsRequest, O: GetAllPatientsResponse },
    { name: "GetAllEncounters", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllEncountersRequest, O: GetAllEncountersResponse },
    { name: "GetAllImmunizations", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllImmunizationsRequest, O: GetAllImmunizationsResponse },
    { name: "GetSinglePastEvent", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetSinglePastEventRequest, O: GetSinglePastEventResponse },
    { name: "GetSinglePastEventDetail", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetSinglePastEventDetailRequest, O: GetSinglePastEventDetailResponse },
    { name: "GetAllPastEventDetails", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllPastEventDetailsRequest, O: GetAllPastEventDetailsResponse }
]);
/**
 * @generated ServiceType for protobuf service phr.past_events.bff.api.v1.ProceduresBFFService
 */
export const ProceduresBFFService = new ServiceType("phr.past_events.bff.api.v1.ProceduresBFFService", [
    { name: "GetAllProcedures", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllProceduresRequest, O: GetAllProceduresResponse }
]);
