// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redBorder__hO8Sy{border:1px solid red}.marginFour__BLLpE{margin:.25rem}.pastEventsContainer__w6mNI{margin-top:8px;padding-top:16px}.pastEventsContainer__w6mNI:focus{border-radius:8px;outline:2px solid HIGHLIGHT}.emptyPastEventsContainer__aWPgd:hover{cursor:default}`, "",{"version":3,"sources":["webpack://./src/past-events/pages/PastEventsPrimaryPage/PastEventsPrimaryCard.module.css"],"names":[],"mappings":"AAAA,kBACE,oBACF,CAEA,mBACE,aACF,CAEA,4BACE,cAAe,CACf,gBACF,CAEA,kCACE,iBAAkB,CAClB,2BACF,CAEA,uCACE,cACF","sourcesContent":[".redBorder {\n  border: 1px solid red;\n}\n\n.marginFour {\n  margin: 0.25rem;\n}\n\n.pastEventsContainer {\n  margin-top: 8px;\n  padding-top: 16px;\n}\n\n.pastEventsContainer:focus {\n  border-radius: 8px;\n  outline: 2px solid HIGHLIGHT;\n}\n\n.emptyPastEventsContainer:hover {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redBorder": `redBorder__hO8Sy`,
	"marginFour": `marginFour__BLLpE`,
	"pastEventsContainer": `pastEventsContainer__w6mNI`,
	"emptyPastEventsContainer": `emptyPastEventsContainer__aWPgd`
};
export default ___CSS_LOADER_EXPORT___;
