import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import getRpcOptions from '../api/getRpcOptions';

import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  GetAllConditionsRequest,
  GetAllConditionsResponse,
  GetAllEncountersDetailsRequest,
  GetAllEncountersRequest,
  GetAllEncountersResponse,
  GetAllImmunizationsRequest,
  GetAllImmunizationsResponse,
  GetAllObservationsRequest,
  GetAllObservationsResponse,
  GetAllPatientsRequest,
  GetAllPatientsResponse,
  GetEncounterDetailsListResponse,
  GetLatestEncountersDetailsRequest,
} from '@verily-src/verily1-protos/phr/past-events/bff/api/v1/past-events_bff';
import {PastEventsBFFServiceClient} from '@verily-src/verily1-protos/phr/past-events/bff/api/v1/past-events_bff.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {createSessionCache} from './CachedEndpoint';

let host = '/api';
const LATEST_ENCOUNTERS_DISPLAYED = 3;
let isLocal = false;
let rpcOptions: RpcOptions = {};

if (process.env.NODE_ENV === 'development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '8997';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
  isLocal = true;
}

class PastEventsService {
  private pastEventsServiceClient?: PastEventsBFFServiceClient;
  private latestEncounterDetailsCache =
    createSessionCache<GetEncounterDetailsListResponse>(() =>
      this._getLatestEncountersDetails()
    );
  private allEncounterDetailsCache =
    createSessionCache<GetEncounterDetailsListResponse>(() =>
      this._getAllEncountersDetails()
    );
  private allConditionsCache = createSessionCache<GetAllConditionsResponse>(
    () => this._getAllConditions()
  );
  private allObservationsCache = createSessionCache<GetAllObservationsResponse>(
    () => this._getAllObservations()
  );
  private allPatientsCache = createSessionCache<GetAllPatientsResponse>(() =>
    this._getAllPatients()
  );
  private allEncountersCache = createSessionCache<GetAllEncountersResponse>(
    () => this._getAllEncounters()
  );
  private allImmunizationsCache =
    createSessionCache<GetAllImmunizationsResponse>(() =>
      this._getAllImmunizations()
    );

  async getClient() {
    if (!isLocal) {
      rpcOptions = await getRpcOptions(); // set the rpcOptions a single time at client level
    }
    if (!this.pastEventsServiceClient) {
      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors: [new LogGrpcUnaryInterceptor()],
      });

      this.pastEventsServiceClient = new PastEventsBFFServiceClient(transport);
    }

    return this.pastEventsServiceClient;
  }

  getLatestEncountersDetails = this.latestEncounterDetailsCache.get;

  private async _getLatestEncountersDetails(): Promise<GetEncounterDetailsListResponse> {
    const request: GetLatestEncountersDetailsRequest = {
      numEncounters: LATEST_ENCOUNTERS_DISPLAYED,
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getLatestEncountersDetails(
      request,
      rpcOptions
    ).response;
  }

  getAllEncountersDetails = this.allEncounterDetailsCache.get;

  private async _getAllEncountersDetails(): Promise<GetEncounterDetailsListResponse> {
    const request: GetAllEncountersDetailsRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllEncountersDetails(request, rpcOptions)
      .response;
  }

  getAllConditions = this.allConditionsCache.get;

  private async _getAllConditions(): Promise<GetAllConditionsResponse> {
    const request: GetAllConditionsRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllConditions(request, rpcOptions)
      .response;
  }

  getAllObservations = this.allObservationsCache.get;

  private async _getAllObservations(): Promise<GetAllObservationsResponse> {
    const request: GetAllObservationsRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllObservations(request, rpcOptions)
      .response;
  }

  getAllPatients = this.allPatientsCache.get;

  private async _getAllPatients(): Promise<GetAllPatientsResponse> {
    const request: GetAllPatientsRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllPatients(request, rpcOptions).response;
  }

  getAllEncounters = this.allEncountersCache.get;

  private async _getAllEncounters(): Promise<GetAllEncountersResponse> {
    const request: GetAllEncountersRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllEncounters(request, rpcOptions)
      .response;
  }

  getAllImmunizations = this.allImmunizationsCache.get;

  private _getAllImmunizations =
    async (): Promise<GetAllImmunizationsResponse> => {
      const request: GetAllImmunizationsRequest = {
        pageSize: 0,
        pageToken: '',
      };

      const pastEventsServiceClient = await this.getClient();

      return pastEventsServiceClient.getAllImmunizations(request, rpcOptions)
        .response;
    };
}

export default new PastEventsService();

export type PastEvent = {
  date: string;
  location?: string;
  summary: string;
  provider: string;
  notes?: string;
};
