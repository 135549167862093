import {Box, CircularProgress, ThemeProvider, useTheme} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  Card,
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {
  Condition,
  Observation,
  Patient,
  RawEncounter,
  RawImmunization,
} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import {primaryPageRoute, rawDataPrimaryRoute} from '../../../Root';
import PastEventsService from '../../../services/PastEventsService';

export function RawDataPrimaryPage() {
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [observations, setObservations] = useState<Observation[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [encounters, setEncounters] = useState<RawEncounter[]>([]);
  const [immunizations, setImmunizations] = useState<RawImmunization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | Raw Data`;
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const {fresh: freshConditions} = PastEventsService.getAllConditions();
      const {fresh: freshObservations} = PastEventsService.getAllObservations();
      const {fresh: freshPatients} = PastEventsService.getAllPatients();
      const {fresh: freshEncounters} = PastEventsService.getAllEncounters();
      const {fresh: freshImmunizations} =
        PastEventsService.getAllImmunizations();
      const conditions = await freshConditions;
      const observations = await freshObservations;
      const patients = await freshPatients;
      const encounters = await freshEncounters;
      const immunizations = await freshImmunizations;
      setConditions(conditions.conditions);
      setObservations(observations.observations);
      setPatients(patients.patients);
      setEncounters(encounters.encounters);
      setImmunizations(immunizations.immunizations);
    } catch (e) {
      console.error('Failed to fetch raw data', e);
      setErrorOccurred(true);
    } finally {
      setIsLoading(false);
    }
  };

  const currTheme = useTheme();
  const {t} = useTranslation();

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    currTheme ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  if (isLoading) {
    return (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  if (errorOccurred) {
    return (
      <ErrorView
        title={'An error occurred'}
        subtitle={'Please try again later'}
        refreshText={'Refresh'}
      />
    );
  }

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <main>
        <Layout
          header={
            <Header
              headerText={'Raw data'}
              hasBackButton={true}
              backButtonDescription={t('back-button-description')}
              onBackButtonClick={() => navigateToUrl(primaryPageRoute)}
            />
          }
        >
          <Box sx={{marginBottom: 2}}>
            <Card
              title={'Conditions'}
              key={2}
              subtitle={`${conditions.length} conditions`}
              useUnlaunchedComponent
              action={{
                onClick: () =>
                  navigateToUrl(`${rawDataPrimaryRoute}/conditions`),
                hasIcon: true,
              }}
            />
          </Box>
          <Box sx={{marginBottom: 2}}>
            <Card
              title={'Encounters'}
              key={5}
              subtitle={`${encounters.length} encounters`}
              useUnlaunchedComponent
              action={{
                onClick: () =>
                  navigateToUrl(`${rawDataPrimaryRoute}/encounters`),
                hasIcon: true,
              }}
            />
          </Box>
          <Box sx={{marginBottom: 2}}>
            <Card
              title={'Immunizations'}
              key={6}
              subtitle={`${immunizations.length} immunizations`}
              useUnlaunchedComponent
              action={{
                onClick: () =>
                  navigateToUrl(`${rawDataPrimaryRoute}/immunizations`),
                hasIcon: true,
              }}
            />
          </Box>
          <Box sx={{marginBottom: 2}}>
            <Card
              title={'Observations'}
              key={3}
              subtitle={`${observations.length} observations`}
              useUnlaunchedComponent
              action={{
                onClick: () =>
                  navigateToUrl(`${rawDataPrimaryRoute}/observations`),
                hasIcon: true,
              }}
            />
          </Box>
          <Box sx={{marginBottom: 2}}>
            <Card
              title={'Patients'}
              key={4}
              subtitle={`${patients.length} patients`}
              useUnlaunchedComponent
              action={{
                onClick: () => navigateToUrl(`${rawDataPrimaryRoute}/patients`),
                hasIcon: true,
              }}
            />
          </Box>
        </Layout>
      </main>
    </ThemeProvider>
  );
}
