import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import PastEventsSectionContent from 'components/PastEventsSectionContent/PastEventsSectionContent';
import {useEffect, useState} from 'react';
import PastEventsService from '../../services/PastEventsService';

type PastEventsSectionCardProps = {
  onClick: () => void;
};

const RELOAD_INTERVAL_MS = 5000;

export default function PastEventsSectionCard(
  props: PastEventsSectionCardProps
) {
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoading(true);
    const {cached, fresh} = PastEventsService.getLatestEncountersDetails();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updatePastEvents(cached.pastEvents);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updatePastEvents(resp.pastEvents))
      .catch(err => {
        //logError(err);

        //if (!isAuthenticationError(err)) {
        // Attempt to load the profile data again in 5 seconds
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoading(false);
        //}
      });
  }

  const updatePastEvents = (pastEvents: PastEvent[]) => {
    setPastEvents(pastEvents);
    setIsLoading(false);
  };

  return (
    <PastEventsSectionContent
      onClick={props.onClick}
      isLoading={isLoading}
      pastEvents={pastEvents}
    />
  );
}
