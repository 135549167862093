import {Card, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import {useTranslation} from 'react-i18next';
import {AUTOMATION_IDS} from '../../common/automationIDs';

interface PastVisitCardProps {
  pastEvent: PastEvent;
  loadingVisual?: JSX.Element;
}

function getFormattedDate(date: string) {
  // Create a JavaScript Date object from the ISO string
  const dateObject = new Date(date);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(dateObject);
}

export default function PastVisitCard({
  pastEvent,
  loadingVisual,
}: PastVisitCardProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  // TODO(ONEVERILY-44736) Check in what the empty start date should be
  const formattedStartDate = pastEvent.startTime
    ? getFormattedDate(pastEvent.startTime)
    : 'No start date';
  const formattedEndDate = pastEvent.endTime
    ? getFormattedDate(pastEvent.endTime)
    : '';
  return (
    <Card
      sx={{
        p: theme.spacing(2),
        gap: theme.spacing(2, 2),
        borderRadius: theme.spacing(2),
        borderColor: theme.enterpriseVsConsumerChanges?.menu?.box?.borderColor,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderWidth: '0.01em',
        borderStyle: 'solid',
      }}
    >
      {loadingVisual ? (
        loadingVisual
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-around',
                gap: theme.spacing(0.5),
              }}
            >
              <Typography
                variant="body1em"
                data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS_START_TIME}${pastEvent.name}`}
              >
                {formattedStartDate}
              </Typography>
              {pastEvent.endTime && (
                <>
                  <Typography variant="body1em">{'-'}</Typography>
                  <Typography
                    variant="body1em"
                    data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS_END_TIME}${pastEvent.name}`}
                  >
                    {formattedEndDate}
                  </Typography>
                </>
              )}
              <Typography variant="body1em">{'•'}</Typography>
              <Typography
                variant="body1em"
                data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS_PRACTITIONER_NAME}${pastEvent.name}`}
              >
                {pastEvent.practitionerName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body1em"
              data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS_LOCATION}${pastEvent.name}`}
            >
              {pastEvent.location}
            </Typography>
          </Box>
        </>
      )}
    </Card>
  );
}
