import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import PastEventCard from 'components/PastEventCard/PastEventCard';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AUTOMATION_IDS} from '../../common/automationIDs';
import PastEventsService from '../../services/PastEventsService';
import styles from './PastEventsSectionPage.module.css';

type PastEventsSectionPageProps = {};

const RELOAD_INTERVAL_MS = 5000;

type PastEventsSectionPageContentProps = {
  isLoadingData: boolean;
  pastEvents: PastEvent[];
};

// Exported for testing
export function PastEventsSectionPageContent(
  props: PastEventsSectionPageContentProps
) {
  const {isLoadingData, pastEvents} = props;

  const theme = useTheme();
  const {t} = useTranslation();

  const pastEventsDarkModeEnabled = config.getBoolean(
    'FEATURE_DARK_MODE_ENABLED'
  );
  return (
    <VerilyMeThemeProvider isDarkModeEnabled={pastEventsDarkModeEnabled}>
      <Layout
        header={
          <Header
            headerText={t('past-events-header')}
            hasBackButton={true}
            backButtonDescription={t('back-button-description')}
            onBackButtonClick={() =>
              (window.location.href = '/me/mobile/health-dashboard')
            }
          />
        }
      >
        {isLoadingData ? (
          <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
            <PillSkeleton
              width={theme.spacing(17.5)}
              height={theme.spacing(3.5)}
            />
          </div>
        ) : (
          <div className={styles.pastEventsContainer}>
            {pastEvents.length === 0 ? (
              <div data-testid={AUTOMATION_IDS.NO_PAST_EVENTS_MESSAGE}>
                <Typography>{t('no-past-events')}</Typography>
              </div>
            ) : (
              <Box sx={{width: '100%'}}>
                <Stack
                  data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS}`}
                  spacing={1}
                >
                  {pastEvents.map(pastEvent => (
                    <PastEventCard key={pastEvent.name} pastEvent={pastEvent} />
                  ))}
                </Stack>
              </Box>
            )}
          </div>
        )}
      </Layout>
    </VerilyMeThemeProvider>
  );
}

export function PastEventsSectionPage(props: PastEventsSectionPageProps) {
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const {t} = useTranslation();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('past-events-header')}`;
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoadingData(true);
    const {cached, fresh} = PastEventsService.getAllEncountersDetails();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updatePastEvents(cached.pastEvents);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updatePastEvents(resp.pastEvents))
      .catch(() => {
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoadingData(false);
      });
  }

  const updatePastEvents = (pastEvents: PastEvent[]) => {
    setPastEvents(pastEvents);
    setIsLoadingData(false);
  };

  return (
    <PastEventsSectionPageContent
      isLoadingData={isLoadingData}
      pastEvents={pastEvents}
    />
  );
}
