import {CircularProgress, ThemeProvider, useTheme} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {Condition} from '@verily-src/verily1-protos/proto/google/fhir/proto/r4/core/resources/condition';
import {Encounter} from '@verily-src/verily1-protos/proto/google/fhir/proto/r4/core/resources/encounter';
import {Immunization} from '@verily-src/verily1-protos/proto/google/fhir/proto/r4/core/resources/immunization';
import {Observation} from '@verily-src/verily1-protos/proto/google/fhir/proto/r4/core/resources/observation';
import {Patient} from '@verily-src/verily1-protos/proto/google/fhir/proto/r4/core/resources/patient';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import {rawDataPrimaryRoute} from '../../../Root';
import PastEventsService from '../../../services/PastEventsService';
import ObjectListViewer from '../../components/ObjectListViewer/ObjectListViewer';

type ResourceType =
  | Condition[]
  | Observation[]
  | Patient[]
  | Encounter[]
  | Immunization[];

export function RawDataSecondaryPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [data, setData] = useState<ResourceType | undefined>();
  const resource = window.location.href.split('/').pop();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | Raw Data`;
  }, []);

  useEffect(() => {
    if (!resource) return;
    loadData();
  }, [resource]);

  const loadData = async () => {
    switch (resource) {
      case 'encounters':
        loadEncounters();
        break;
      case 'conditions':
        loadConditions();
        break;
      case 'observations':
        loadObservations();
        break;
      case 'patients':
        loadPatients();
        break;
      case 'immunizations':
        loadImmunizations();
        break;
      default:
        console.error('Unknown resource');
        setErrorOccurred(true);
    }
  };

  const loadEncounters = async () => {
    const {cached, fresh} = PastEventsService.getAllEncounters();
    setIsLoading(true);
    updateData(
      cached?.encounters.map(encounter => encounter.fhirEncounter as Encounter)
    );
    fresh
      .then(data =>
        updateData(
          data.encounters.map(encounter => encounter.fhirEncounter as Encounter)
        )
      )
      .catch(() => {
        console.error('Failed to fetch encounters');
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  const loadPatients = async () => {
    const {cached, fresh} = PastEventsService.getAllPatients();
    setIsLoading(true);
    updateData(cached?.patients.map(patient => patient.fhirPatient as Patient));
    fresh
      .then(data =>
        updateData(data.patients.map(patient => patient.fhirPatient as Patient))
      )
      .catch(() => {
        console.error('Failed to fetch patients');
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  const loadImmunizations = async () => {
    const {cached, fresh} = PastEventsService.getAllImmunizations();
    setIsLoading(true);
    updateData(
      cached?.immunizations.map(
        immunization => immunization.fhirImmunization as Immunization
      )
    );
    fresh
      .then(data =>
        updateData(
          data.immunizations.map(
            immunization => immunization.fhirImmunization as Immunization
          )
        )
      )
      .catch(() => {
        console.error('Failed to fetch immunizations');
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  const loadConditions = async () => {
    const {cached, fresh} = PastEventsService.getAllConditions();
    setIsLoading(true);
    updateData(
      cached?.conditions.map(condition => condition.fhirCondition as Condition)
    );
    fresh
      .then(data =>
        updateData(
          data.conditions.map(condition => condition.fhirCondition as Condition)
        )
      )
      .catch(() => {
        console.error('Failed to fetch conditions');
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  const loadObservations = async () => {
    const {cached, fresh} = PastEventsService.getAllObservations();
    setIsLoading(true);
    updateData(
      cached?.observations.map(
        observation => observation.fhirObservation as Observation
      )
    );
    fresh
      .then(data =>
        updateData(
          data.observations.map(
            observation => observation.fhirObservation as Observation
          )
        )
      )
      .catch(() => {
        console.error('Failed to fetch observations');
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  const updateData = (data?: ResourceType) => {
    if (!data) return;
    setIsLoading(false);
    setData(data);
  };

  const resourceToTitle = (resource?: string) => {
    switch (resource) {
      case 'encounters':
        return 'Encounters';
      case 'conditions':
        return 'Conditions';
      case 'observations':
        return 'Observations';
      case 'patients':
        return 'Patients';
      case 'immunizations':
        return 'Immunizations';
      default:
        return 'Unknown';
    }
  };

  const currTheme = useTheme();
  const {t} = useTranslation();

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    currTheme ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  if (isLoading) {
    return (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  if (errorOccurred || !data) {
    return (
      <ErrorView
        title={'An error occurred'}
        subtitle={'Please try again later'}
        refreshText={'Refresh'}
      />
    );
  }

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <main>
        <Layout
          header={
            <Header
              headerText={resourceToTitle(resource)}
              hasBackButton={true}
              backButtonDescription={t('back-button-description')}
              onBackButtonClick={() => navigateToUrl(rawDataPrimaryRoute)}
            />
          }
        >
          <ObjectListViewer data={data} />
        </Layout>
      </main>
    </ThemeProvider>
  );
}
