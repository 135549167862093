import {ThemeProvider, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  List,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {PastEvent} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PastEventsService from '../../../services/PastEventsService';
import {AUTOMATION_IDS} from '../../automationIDs';
import PastEventCard from '../../components/PastEventCard/PastEventCard';

const RELOAD_INTERVAL_MS = 5000;

type PastEventsSecondaryPageContentProps = {
  isLoadingData: boolean;
  pastEvents: PastEvent[];
};

// Exported for testing
export function PastEventsSecondaryPageContent(
  props: PastEventsSecondaryPageContentProps
) {
  const {isLoadingData, pastEvents} = props;

  const currTheme = useTheme();
  const {t} = useTranslation();

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    currTheme ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <main>
        <Layout
          header={
            <Header
              headerText={t('past-events-header')}
              hasBackButton={true}
              backButtonDescription={t('back-button-description')}
              onBackButtonClick={() =>
                (window.location.href = '/me/mobile/health-dashboard')
              }
            />
          }
        >
          {isLoadingData ? (
            <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
              <PillSkeleton
                width={theme.spacing(17.5)}
                height={theme.spacing(3.5)}
              />
            </div>
          ) : (
            <>
              {pastEvents.length === 0 ? (
                <div data-testid={AUTOMATION_IDS.NO_PAST_EVENTS_MESSAGE}>
                  <Typography>{t('no-past-events')}</Typography>
                </div>
              ) : (
                <div
                  data-testid={`${AUTOMATION_IDS.SECTION_PAGE.ALL_ENCOUNTERS}`}
                >
                  <List>
                    {pastEvents.map(pastEvent => (
                      <PastEventCard
                        key={pastEvent.name}
                        pastEvent={pastEvent}
                        linkToTertiaryPage={config.getBoolean(
                          'FEATURE_PAST_VISITS_DETAILS_ENABLED'
                        )}
                      />
                    ))}
                  </List>
                </div>
              )}
            </>
          )}
        </Layout>
      </main>
    </ThemeProvider>
  );
}

export function PastEventsSecondaryPage() {
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const {t} = useTranslation();

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('past-events-header')}`;
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoadingData(true);
    const {cached, fresh} = PastEventsService.getAllEncountersDetails();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updatePastEvents(cached.pastEvents);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updatePastEvents(resp.pastEvents))
      .catch(() => {
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoadingData(false);
      });
  }

  const updatePastEvents = (pastEvents: PastEvent[]) => {
    setPastEvents(pastEvents);
    setIsLoadingData(false);
  };

  return (
    <PastEventsSecondaryPageContent
      isLoadingData={isLoadingData}
      pastEvents={pastEvents}
    />
  );
}
